<template>
  <div>
    <button class="mb-3" @click="versionKey = versionKey+1; init()">REFRESjJ</button>


      <div class="banana p-3">
      saassa
      
      </div>

    <div>
      <div></div>
    </div>
    

    <b-tabs :key="'sadasdsadasdasdas'+versionKey" >
      <b-tab title="test 1">
        <div class="container position-relative">
          <div class="p-5 m-5">
            <div class="burst-bubble" v-for="i in 5"/>

            <div class="color-block2 cursor-pointer" v-for="i in 30" :data-x="120" @click="initAnimation()"/>

            <div class="mt-5"></div>

            <div class="loading-octagon-container">
              <div v-for="i in 10" class="loading-octagon-trail">
                <span/>
              </div>
            </div>

            <div class="expanding d-inline-block">
              <h4 class="d-inline-block">Zoinks</h4>
            </div>
            <div class="banana-spin">BANANA</div>

          </div>

          <b-skeleton class="mb-5"/>

          <div>====================</div>
          <div @click="fuckEverything()">@click="quebrarTudo()"</div>
          <div>====================</div>


          <div class="bg-favorite mt-3">
            <div class="my-1" v-for="i in 10">
              <b-collapse accordion="seqsso" :visible="true">salada gamer</b-collapse>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="test 2">
        <div class="container position-relative">

          <b-button @click="animationTest2()" variant="purple" class="m-2">move</b-button>

          <div class="d-flex justify-content-start">
            Loop: <b-form-checkbox switch class="ml-1 mr-3" v-model="animation2.loop"/>

            Restoring: <b-form-checkbox switch class="ml-1" v-model="animation2.restore"/>

          </div>
          <div class="w-50">
            <label>Tempo:</label>
            <b-form-input type="range" class="mb-1" v-model="animation2.time" min="300" max="5000"/>
            <label>Tamanho:</label>
            <b-form-input type="range" class="mb-1" v-model="animation2.t" min="0.1" max="5" step="0.1"/>
            <label>Distancia:</label>
            <b-form-input type="range" class="mb-1" v-model="animation2.d" min="1" max="25"/>
            <label>Level de Epilepsia:</label>
            <b-form-input type="range" class="mb-1" v-model="animation2.e" min="1" max="100"/>

            
            <label>Numero de pirulitos:</label>
            <div class="d-flex">
              <b-form-input type="range" class="mb-1 mr-2" v-model="animation2.n" min="1" max="150" step="1" /> <h3>{{animation2.n}}</h3>
            </div>
          </div>

          <div :key="animation2.n"> 
            <div class="moving-block" @click="animationTest2()" v-for="i in parseInt(animation2.n)" :key="i + 'scooby-doo-' + random(1, 20000)"/>
         </div> 
        </div>
      </b-tab>

      <b-tab title="test L.E." active>
        <!-- ============= ACTIVE ============= -->     
        
        <logic-expression v-model="leVar" :isRoot="true"/>

        <!-- ============= ACTIVE ============= -->
      </b-tab>
      <b-tab title="Conversor Middleware">

        <div class="d-flex align-items-center font-weight-bolder my-1 justify-content-end" align="right">
          <div>Encriptar (JSON -> B64)</div>
          <b-form-checkbox switch class="mx-1" v-model="isEncription"/>
          <div>Decriptar (B64 -> JSON)</div>
        </div>

        <b-collapse :visible="isEncription">
          <div class="font-weight-bolder">Middlewares JSON:</div>
          <json-editor v-model="encriptionData" v-if="isEncription" class="pb-3"></json-editor>
        </b-collapse>
        
        <b-collapse :visible="!isEncription">
          <div class="font-weight-bolder">Exported Base 64:</div>
          <b-form-textarea v-model="encriptionData" v-if="!isEncription"/>
        </b-collapse>

        <div align="right">
          <b-button class="mt-1 px-3 text-black" variant="success" @click="encriptDecript()">
            <span class="text-black">RUN</span>
          </b-button>
        </div>

        <div class="mt-2" v-if="encriptionResult">
          <div class="font-weight-bolder">Result: </div>
          
          <json-editor :contenteditable="false" :height="600" v-model="encriptionResult.data" v-if="encriptionResult.type == 'json'" class="position-relative"></json-editor>
          <b-form-textarea v-else v-model="encriptionResult.data">

          </b-form-textarea>
        </div>
        
      </b-tab>
      <b-tab title="sass api">
        
        <div class="sass-api-test">
          sss
        </div>
        
      </b-tab>
    </b-tabs>

    <br class="my-5">



    <div style="background-color: rgb(0,0,0,0.7);" class="p-3">
      <div v-for="i in ['favorite','dark','secondary','primary','success','warning','danger','info','purple','dark-blue','black', 'light']" class="mt-25">
        <div :class="'bg-' + i" class="p-50"></div>
      </div>
    </div>


  </div>
</template>

<script>
import anime, { easing } from 'animejs';
import { 
  BCol,
  BRow,
  BIcon,
  BSkeleton,
  BCollapse,
  BTabs,
  BTab,
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
 } from 'bootstrap-vue';
import LogicExpression from '@/views/pages/middleware/MiddlewareManagement/Components/Comparison/LogicExpression.vue'
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue"
import * as StringCompressor from '@/custom/class/FunctionClasses/StringCompressor.js'
import JsonViewer from 'vue-json-viewer';

 export default {
  components: {
    BCol,
    BRow,
    BIcon,
    BSkeleton,
    BCollapse,
    BTabs,
    BTab,
    BButton,
    BFormInput,
    BFormCheckbox,
    LogicExpression,
    BFormTextarea,
    JsonEditor,
    JsonViewer,
  },
  data() {
    return {
      versionKey:0,
      animation: undefined,
      test2Counter: 0,
      test2Timer: 0,
      animation2:{
        t: 1,
        d: 10,
        e: 1,
        time: 500,
        n : 1,
        loop: false,
        restore: false,
        runnin: undefined,
      },
      popupAnimation: undefined,
      leVar: "",
      jsonData:{
  "token": "549fc8ae-05ec-4e05-8f3e-f351f529f85b",
  "transmission": "1e783a12-fe80-48cb-a0e1-33e88589c8a2",
  "origin": {
    "type": "webhook",
    "service": "S",
    "event": "E",
    "scheduler": null,
    "webhook": "Log Me Up"
  },
  "level": "error",
  "success": false,
  "elapsed_time": 2221539,
  "tranferred_data": 0,
  "created_at": 1678305221716,
  "data": {
    "$IN": [
      {
        "created_at": 1678305219498,
        "elapsed_time": 2406,
        "error": false,
        "event": "E",
        "id": 14,
        "is_fatal": false,
        "logs": {
          "catch": null,
          "then": null,
          "try": null
        },
        "message": "",
        "middleware_identifier": "3f2706a7-d4b2-4df1-b754-2540e7664684",
        "middlewares": [],
        "name": "Fronnintan, The Powerful",
        "service": "S",
        "type": 16
      },
      {
        "created_at": 1678305219498,
        "elapsed_time": 130,
        "error": false,
        "event": "E",
        "id": 15,
        "is_fatal": false,
        "logs": {
          "catch": null,
          "then": null,
          "try": null
        },
        "message": "",
        "middleware_identifier": "f3c01363-4f83-486b-b20f-279c14d89ba0",
        "middlewares": null,
        "name": "End Fronnintan, The Powerful",
        "service": "S",
        "type": 18
      },
      {
        "created_at": 1678305219498,
        "elapsed_time": 411818,
        "error": true,
        "event": "E",
        "id": 16,
        "is_fatal": false,
        "logs": {
          "catch": [
            {
              "created_at": 1678305219498812,
              "data": null,
              "error": true,
              "message": "string to int conversion error",
              "type": 48
            }
          ],
          "then": [
            {
              "created_at": 1678305219498803,
              "data": null,
              "error": true,
              "message": "source INFO is read only",
              "type": 50
            }
          ],
          "try": null
        },
        "message": "agent 0 fatal with message source INFO is read only",
        "middleware_identifier": "a0d65d5a-74a0-4976-9797-71eceb2d0006",
        "middlewares": null,
        "name": "Make Array",
        "service": "S",
        "type": 3
      },
      {
        "created_at": 1678305219498,
        "elapsed_time": 2001039871,
        "error": false,
        "event": "E",
        "id": 17,
        "is_fatal": false,
        "logs": {
          "catch": null,
          "then": null,
          "try": null
        },
        "message": "",
        "middleware_identifier": "18e215be-b377-4dd8-bded-486389d50af8",
        "middlewares": null,
        "name": "Tithylos, Protector Of The Sky",
        "service": "S",
        "type": 3
      },
      {
        "created_at": 1678305221499,
        "elapsed_time": 209741980,
        "error": true,
        "event": "E",
        "id": 18,
        "is_fatal": false,
        "logs": {
          "catch": null,
          "then": null,
          "try": [
            {
              "created_at": 1678305221,
              "data": {
                "body": "<html>\r\n<head><title>401 Authorization Required</title></head>\r\n<body>\r\n<center><h1>401 Authorization Required</h1></center>\r\n<hr><center>openresty</center>\r\n</body>\r\n</html>\r\n",
                "code": 401,
                "header": {
                  "Content-Length": [
                    "176"
                  ],
                  "Content-Type": [
                    "text/html"
                  ],
                  "Date": [
                    "Wed, 08 Mar 2023 19:53:41 GMT"
                  ],
                  "Server": [
                    "nginx-rc"
                  ],
                  "Www-Authenticate": [
                    "Basic realm=\"Staging Area\""
                  ]
                }
              },
              "error": true,
              "message": "Requester response info",
              "type": 0
            }
          ]
        },
        "message": "request returned with status code 401 Unauthorized",
        "middleware_identifier": "a2c8131f-e12e-41bd-9c70-3d6ec1642abf",
        "middlewares": null,
        "name": "gamrer",
        "service": "S",
        "type": 2
      },
      {
        "created_at": 1678305221709,
        "elapsed_time": 6706614,
        "error": false,
        "event": "E",
        "id": 19,
        "is_fatal": false,
        "logs": {
          "catch": null,
          "then": null,
          "try": null
        },
        "message": "",
        "middleware_identifier": "09d890ac-e02f-4836-b579-fb63d1edbb0a",
        "middlewares": [
          {
            "created_at": 1678305221710,
            "elapsed_time": 1332,
            "error": false,
            "event": "E",
            "id": 21,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "fc6e857e-5c68-45b7-90a4-f9810476bf2e",
            "middlewares": null,
            "name": "Wildflame",
            "service": "S",
            "type": 3
          },
          {
            "created_at": 1678305221710,
            "elapsed_time": 636469,
            "error": false,
            "event": "E",
            "id": 22,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "c717430e-09b0-4770-9a8b-e057a1e5f6f8",
            "middlewares": [
              {
                "created_at": 1678305221710,
                "elapsed_time": 571261,
                "error": false,
                "event": "E",
                "id": 24,
                "is_fatal": false,
                "logs": {
                  "catch": null,
                  "then": null,
                  "try": null
                },
                "message": "",
                "middleware_identifier": "e6a6266e-afba-4754-a7a0-92075a5086f2",
                "middlewares": null,
                "name": "Super Gamer",
                "service": "S",
                "type": 3
              }
            ],
            "name": "Qirmererth, The White One",
            "service": "S",
            "type": 16
          },
          {
            "created_at": 1678305221710,
            "elapsed_time": 498,
            "error": false,
            "event": "E",
            "id": 23,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "78adae0b-b84d-427f-b5b9-f7f22a799e31",
            "middlewares": null,
            "name": "End Qirmererth, The White One",
            "service": "S",
            "type": 18
          },
          {
            "created_at": 1678305221710,
            "elapsed_time": 1875759,
            "error": false,
            "event": "E",
            "id": 25,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "e55e4e70-f2a5-4b42-b770-b636a6bfadeb",
            "middlewares": null,
            "name": "Piphoross, The White",
            "service": "S",
            "type": 3
          },
          {
            "created_at": 1678305221712,
            "elapsed_time": 1817,
            "error": false,
            "event": "E",
            "id": 21,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "fc6e857e-5c68-45b7-90a4-f9810476bf2e",
            "middlewares": null,
            "name": "Wildflame",
            "service": "S",
            "type": 3
          },
          {
            "created_at": 1678305221712,
            "elapsed_time": 502188,
            "error": false,
            "event": "E",
            "id": 22,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "c717430e-09b0-4770-9a8b-e057a1e5f6f8",
            "middlewares": [
              {
                "created_at": 1678305221712,
                "elapsed_time": 442408,
                "error": false,
                "event": "E",
                "id": 24,
                "is_fatal": false,
                "logs": {
                  "catch": null,
                  "then": null,
                  "try": null
                },
                "message": "",
                "middleware_identifier": "e6a6266e-afba-4754-a7a0-92075a5086f2",
                "middlewares": null,
                "name": "Super Gamer",
                "service": "S",
                "type": 3
              }
            ],
            "name": "Qirmererth, The White One",
            "service": "S",
            "type": 16
          },
          {
            "created_at": 1678305221713,
            "elapsed_time": 372,
            "error": false,
            "event": "E",
            "id": 23,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "78adae0b-b84d-427f-b5b9-f7f22a799e31",
            "middlewares": null,
            "name": "End Qirmererth, The White One",
            "service": "S",
            "type": 18
          },
          {
            "created_at": 1678305221713,
            "elapsed_time": 845591,
            "error": false,
            "event": "E",
            "id": 25,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "e55e4e70-f2a5-4b42-b770-b636a6bfadeb",
            "middlewares": null,
            "name": "Piphoross, The White",
            "service": "S",
            "type": 3
          },
          {
            "created_at": 1678305221714,
            "elapsed_time": 314,
            "error": false,
            "event": "E",
            "id": 21,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "fc6e857e-5c68-45b7-90a4-f9810476bf2e",
            "middlewares": null,
            "name": "Wildflame",
            "service": "S",
            "type": 3
          },
          {
            "created_at": 1678305221714,
            "elapsed_time": 323260,
            "error": false,
            "event": "E",
            "id": 22,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "c717430e-09b0-4770-9a8b-e057a1e5f6f8",
            "middlewares": [
              {
                "created_at": 1678305221714,
                "elapsed_time": 289151,
                "error": false,
                "event": "E",
                "id": 24,
                "is_fatal": false,
                "logs": {
                  "catch": null,
                  "then": null,
                  "try": null
                },
                "message": "",
                "middleware_identifier": "e6a6266e-afba-4754-a7a0-92075a5086f2",
                "middlewares": null,
                "name": "Super Gamer",
                "service": "S",
                "type": 3
              }
            ],
            "name": "Qirmererth, The White One",
            "service": "S",
            "type": 16
          },
          {
            "created_at": 1678305221714,
            "elapsed_time": 129,
            "error": false,
            "event": "E",
            "id": 23,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "78adae0b-b84d-427f-b5b9-f7f22a799e31",
            "middlewares": null,
            "name": "End Qirmererth, The White One",
            "service": "S",
            "type": 18
          },
          {
            "created_at": 1678305221714,
            "elapsed_time": 1044687,
            "error": false,
            "event": "E",
            "id": 25,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "e55e4e70-f2a5-4b42-b770-b636a6bfadeb",
            "middlewares": null,
            "name": "Piphoross, The White",
            "service": "S",
            "type": 3
          },
          {
            "created_at": 1678305221715,
            "elapsed_time": 387,
            "error": false,
            "event": "E",
            "id": 21,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "fc6e857e-5c68-45b7-90a4-f9810476bf2e",
            "middlewares": null,
            "name": "Wildflame",
            "service": "S",
            "type": 3
          },
          {
            "created_at": 1678305221715,
            "elapsed_time": 316239,
            "error": false,
            "event": "E",
            "id": 22,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "c717430e-09b0-4770-9a8b-e057a1e5f6f8",
            "middlewares": [
              {
                "created_at": 1678305221715,
                "elapsed_time": 282796,
                "error": false,
                "event": "E",
                "id": 24,
                "is_fatal": false,
                "logs": {
                  "catch": null,
                  "then": null,
                  "try": null
                },
                "message": "",
                "middleware_identifier": "e6a6266e-afba-4754-a7a0-92075a5086f2",
                "middlewares": null,
                "name": "Super Gamer",
                "service": "S",
                "type": 3
              }
            ],
            "name": "Qirmererth, The White One",
            "service": "S",
            "type": 16
          },
          {
            "created_at": 1678305221715,
            "elapsed_time": 106,
            "error": false,
            "event": "E",
            "id": 23,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "78adae0b-b84d-427f-b5b9-f7f22a799e31",
            "middlewares": null,
            "name": "End Qirmererth, The White One",
            "service": "S",
            "type": 18
          },
          {
            "created_at": 1678305221715,
            "elapsed_time": 618655,
            "error": false,
            "event": "E",
            "id": 25,
            "is_fatal": false,
            "logs": {
              "catch": null,
              "then": null,
              "try": null
            },
            "message": "",
            "middleware_identifier": "e55e4e70-f2a5-4b42-b770-b636a6bfadeb",
            "middlewares": null,
            "name": "Piphoross, The White",
            "service": "S",
            "type": 3
          }
        ],
        "name": "Zeniphy, The Clumsy One das asd asd asd a za dasd sa",
        "service": "S",
        "type": 40
      },
      {
        "created_at": 1678305221716,
        "elapsed_time": 188,
        "error": false,
        "event": "E",
        "id": 20,
        "is_fatal": false,
        "logs": {
          "catch": null,
          "then": null,
          "try": null
        },
        "message": "",
        "middleware_identifier": "74d42423-23e7-46f9-8103-1dfcaa25facd",
        "middlewares": null,
        "name": "End Zeniphy, The Clumsy One das asd asd asd a za dasd sa",
        "service": "S",
        "type": 45
      },
      {
        "created_at": 1678305221716,
        "elapsed_time": 286,
        "error": true,
        "event": "E",
        "id": 26,
        "is_fatal": true,
        "logs": {
          "catch": null,
          "then": [
            {
              "created_at": 1678305221716319,
              "data": [],
              "error": true,
              "message": "javascript runtime error [--fuck--]  at agent:8:1(10)",
              "type": 18
            }
          ],
          "try": null
        },
        "message": "agent 0 fatal with message javascript runtime error [--fuck--]  at agent:8:1(10)",
        "middleware_identifier": "494c88b6-c7dd-4983-b4d2-f42d2e877bb6",
        "middlewares": null,
        "name": "Make Return and Error the fuck out ............................. a ......... a......... a......",
        "service": "S",
        "type": 3
      }
    ],
    "$OUT": [],
    "info": {
      "IP": [
        "10.1.0.95"
      ],
      "URL": "/webhook/async/1e783a12-fe80-48cb-a0e1-33e88589c8a2/Log%20Me%20Up/c7db48464d080bac6ed9a99de429b4a5007b56170717492d32b7c22d3c1026f54a7f162201",
      "body": "",
      "header": {
        "Accept": [
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,*/*;q=0.8"
        ],
        "Accept-Encoding": [
          "gzip, deflate"
        ],
        "Accept-Language": [
          "pt-BR,pt;q=0.8,en-US;q=0.5,en;q=0.3"
        ],
        "Connection": [
          "keep-alive"
        ],
        "Dnt": [
          "1"
        ],
        "Upgrade-Insecure-Requests": [
          "1"
        ],
        "User-Agent": [
          "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:109.0) Gecko/20100101 Firefox/110.0"
        ]
      },
      "response": {
        "BODY": {
          "Gamer": "deveras",
          "true": false
        },
        "CODE": 200
      }
    }
  },
  "message": "fatal middleware error chain E.S.$IN returned with error fatal middleware Make Return and Error the fuck out ............................. a ......... a......... a...... returned error agent 0 fatal with message javascript runtime error [--fuck--]  at agent:8:1(10)",
  "version": "v2.0",
  "script_id": 134
      },


      isEncription: false,
      encriptionData: undefined,
      encriptionResult: undefined,
    }
  },
  mounted () {
    this.importantSetupStep()
    this.init()
    
    
  },
  methods: {
    async init(){
      await this.$nextTick()
      
     anime({
      targets: ".expanding",
      rotate: 360
     })
    },
    importantSetupStep(){
      if (this.$route.params.routeParam == 'fiqon-special spin'){

        let css = `
          @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
          @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
          @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
          *{
            animation:spin 3s ease-in !important;
            animation-iteration-count: 1 !important;
          }
        `
        let head = document.head || document.getElementsByTagName('head')[0];
        let style = document.createElement('style');
        style.appendChild(document.createTextNode(css));
        head.appendChild(style);
      }
    },
    initAnimation(){
      if (this.animation && !this.animation.completed){
        return
      }
      anime({
        targets: '.color-block2',
        translateX: ()=>{return anime.random(-300, 380)},
        translateY: ()=>{return anime.random(-300, 380)},
        rotate: ()=>{return anime.random(-360, 360)},
        "background-color": ()=>{return `rgb(${anime.random(255, 255)}, ${anime.random(0, 255)}, ${anime.random(0, 255)})`},
        "border-radius": ()=>{return anime.random(0, 20)},
        scale: ()=>{return anime.random(0.8 , 1.4)},
        "box-shadow": '0 0 25px black',
        easing: 'easeOutElastic(1, 0.8)',
        duration: 1200,
        direction: 'alternate',
        loop: false,
      })

      anime({
        targets: '.burst-bubble',
        rotate: ()=>{return anime.random(-360, 360)},
        translateX: ()=>{return anime.random(-100, 100)},
        translateY: ()=>{return anime.random(-100, 100)},
        'border-radius': ()=>{return anime.random(1, 30)},
        "background-color": ()=>{return `rgb(${anime.random(255, 255)}, ${anime.random(0, 255)}, ${anime.random(0, 255)})`},
        scale: [0.2 , ()=>{return 500/anime.random(40, 80)}],
        easing: 'easeOutElastic(1,1)',
        "box-shadow": ()=>{return `0 0 1px 5px rgb(${anime.random(0, 255)}, ${anime.random(0, 255)}, ${anime.random(0, 255)})`},
        opacity: [1 , 0],
        duration: 1200,
        direction: 'alternate',
        loop: false,
      })
    },
    animationTest2(){
      if (this.animation2.running){
        this.animation2.running.pause
      }

      let v = (this.animation2)

      this.animation2.running = anime({
        targets: ".moving-block",
        rotate: [0, anime.random(-180, 180)],
        "background-color": ()=>{return `rgb( ${anime.random(0,255)}, ${anime.random(0,255)} , ${anime.random(0,255)} )`},
        "margin-left": ()=>{return anime.random(-300, 300) * v.d/10},
        "margin-bottom": ()=>{return anime.random(-300, 300) * v.d/10},
        "border-radius": anime.random(1 , 20),
        scale: anime.random(0.8 , 1.8) * v.t,
        easing: `easeOutElastic(${1 /v.e}, ${1 / v.e})`,
        autoplay: true,
        duration: this.animation2.time,
        direction: v.restore ? 'alternate' : 'normal',
        complete: ()=>{ if (this.animation2.loop) this.animationTest2()}
      })

    },
    fuckEverything(){
      let salada = []
      let totalz = 0 

      function fuckEverything(){
      
      setTimeout(() => {
        fuckEverything()
      }, 150);

      while (true){
          console.log('FUCKME')
          console.log("MEFUCK")
          let m = Math.random()*10000
          salada.push(m)
          totalz = totalz + salada
        }
      }
      fuckEverything()
    },
    random(a, b){
      return anime.random(a, b)
    },
    encriptDecript(){  
      let r;
      let data = structuredClone(this.encriptionData)
      
      if (!this.isEncription){
        r = StringCompressor.decompressFromBase64(data)
        this.encriptionResult ={
          data: JSON.parse(r),
          type: "json"
        }

      } else {
        console.log('%c'+JSON.stringify(data), 'color: red');
        
        r = StringCompressor.compressToBase64(JSON.stringify(data))
        this.encriptionResult ={
          data: r,
          type: "str"
        } 
      }


    },
  },
};  
</script>

<style lang="scss">
@import "@/assets/scss/json-viewer-themes.scss";


</style>



<style lang="scss" scoped>

.container{
  width: 70%;
  height: 1000px;
  border: 3px solid var(--secondary);
  border-radius: 8px;
  margin: auto;
}
.color-block{
  width: 30px;
  height: 80px;
  background-color: cyan;
  box-shadow: 0 0 25px black;
}
.color-block2{
  width: 30px;
  height: 30px;
  background-color: cyan;
  border-radius: 3px;
  position: absolute;
}
.burst-bubble{
  position: absolute;
  border-radius: 1000px;
  pointer-events: none;
  width: 30px;
  height: 30px;
}

.loading-octagon-container{
  width: 300px;
  height: 300px;
  -border: 2px solid white;
  border-radius: 10px;
  position: relative !important;
  .loading-octagon-trail{
    height: 20px;
    -outline: 1px solid black;
    position: absolute;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
    span{
      display: block;
      background-color: var(--purple);
      border-radius: 1000px;

      width: 50px;
      height: 50px;
    }
  }
}


@keyframes banana{
  from {rotate: 0;}
  to {rotate: 720deg}
}

.banana-spin{
  animation: 3s banana linear infinite;
}

.moving-block{
  width: 45px;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

</style>